import { BrowserRouter } from 'react-router-dom';
import * as React from 'react';

const basePath = window.basePath ? window.basePath : '/'

export default class ReactRouter extends React.Component {
  render() {
    return <BrowserRouter basename={ basePath }>
      { this.props.children }
    </BrowserRouter>;
  }
};