import * as React from 'react';
import { nameofFactory } from 'library/code/generics';
import * as styles from './styles.css';
import { ErrorMessageHelper, ErrorMessageMap, FormGroup } from 'library/form/components';

declare namespace QuantityLimitEditor {
    export interface Props {
        predicateData: QuantityLimitPredicateData;
        editorData: QuantityLimitEditorMeta;
        setOnSave: EditorSetOnSave;
        isNew: boolean;
    }

    export interface State {
        predicateData: QuantityLimitPredicateData;
        formErrors?: ErrorMessageMap;
    }
}

export class QuantityLimitEditor extends React.Component<QuantityLimitEditor.Props, QuantityLimitEditor.State> {

    constructor(props: QuantityLimitEditor.Props, context: any) {
        super(props, context)

        const { setOnSave, isNew } = props;

        this.onSave = this.onSave.bind(this);
        setOnSave(this.onSave);

        let predicateData = {
            dataType: 'QuantityLimit',
            quantity: '',
            unit: '',
            ignoreGross: false,
            quantitySource: ''
        } as QuantityLimitPredicateData

        if (!isNew) {
            predicateData = props.predicateData
            if (!predicateData.quantitySource) {
              predicateData.quantitySource = 'dg'; // Set old versions of this rule to be dg, as that is the default when opened up
            }
        }

        this.state = {
            predicateData: predicateData,
            formErrors: {} as ErrorMessageMap
        };
    }

    render() {
        const { editorData } = this.props;
        const { formErrors, predicateData } = this.state;
        const { quantitySources } = editorData;

        var errorHelper = new ErrorMessageHelper<QuantityLimitPredicateData>(formErrors);

        return (
            <div>
                <FormGroup
                    label="Quantity to compare"
                    labelFor="quantity-source"
                    additionalWrapper="select-wrapper"
                    validationMessages={ errorHelper.errorsFor('quantitySource') }>
                  <select className="form-control" onChange={ this.updateValue('quantitySource') } id="quantity-source" value={ predicateData.quantitySource || '' }>
                      <option key="default" value="">Please select an option</option>
                      {
                          quantitySources.map(opt =>
                              <option value={ opt.value }
                                  key={ opt.value }>
                                  { opt.label }
                              </option>
                          )
                      }
                  </select>
                </FormGroup>

                <FormGroup
                    label="Quantity"
                    labelFor="quantity-limit" validationMessages={ errorHelper.errorsFor('quantity') }>
                    <span className="form-input input-wrapper">
                        <input type="text" name="quantity-limit"
                            className="form-control"
                            onChange={ this.updateValue('quantity') }
                            value={ predicateData.quantity }
                            />
                    </span>
                </FormGroup>

                <FormGroup
                    label="Unit"
                    labelFor="quantity-unit"
                    additionalWrapper="select-wrapper"
                    validationMessages={ errorHelper.errorsFor('unit') }>
                    <select className="form-control" onChange={ this.updateValue('unit') } id="quantity-unit" value={ predicateData.unit || '' }>
                        <option key="default" value="">Please select an option</option>
                        {
                            editorData.units.map(opt =>
                                <option value={ opt.value }
                                    key={ opt.value }>
                                    { opt.label }
                                </option>
                            )
                        }
                    </select>
                </FormGroup>

                <FormGroup
                    label="Unit comparison"
                    labelFor="ignore-gross" validationMessages={ errorHelper.errorsFor('ignoreGross') }>
                    <span className="multiple-text-inputs form-subgroup-stacked">
                        <label>
                          <input type="checkbox" name="ignore-gross"
                            value="true"
                            checked={ predicateData.ignoreGross }
                            onChange={ this.updateCheckboxSelected('ignoreGross') }
                            />
                          Ignore gross when comparing units
                        </label>
                    </span>
                </FormGroup>
            </div>
        )
    }

    onSave() : EditorOnSaveData {
        const { predicateData } = this.state;

        return {
            predicateData: predicateData,
            isValid: this.isValid(predicateData),
            summary: this.getSummary(predicateData)
        } as EditorOnSaveData;
    }

    updateCheckboxSelected(propertyName: keyof QuantityLimitPredicateData) {

      return (e) => {
          const { predicateData } = this.state;

          const newData = {
              ...predicateData,
              [propertyName]: e.currentTarget.checked
          } as QuantityLimitPredicateData;

          this.setState({
              predicateData: newData
          });
      };
  }

    updateValue(propertyName: keyof QuantityLimitPredicateData) {

        return (e) => {
            const { predicateData } = this.state;

            const newData = {
                ...predicateData,
                [propertyName]: e.target.value
            } as QuantityLimitPredicateData;

            this.setState({
                predicateData: newData
            });
        };
    }

    isValid(data: QuantityLimitPredicateData) : boolean {

        var errorHelper = new ErrorMessageHelper<QuantityLimitPredicateData>();

        if (!data.quantity) {
            errorHelper.addErrorFor('quantity', 'Please provide a quantity');
        }

        if (!data.quantitySource) {
            errorHelper.addErrorFor('quantitySource', 'Please select a quantity source');
        }

        if (!data.unit) {
            errorHelper.addErrorFor('unit', 'Please provide a unit');
        }

        this.setState({
            ...this.state,
            formErrors: errorHelper.errors
        });

        return errorHelper.isValid();
    }

    getSummary(data: QuantityLimitPredicateData) : string {
        const { editorData } = this.props;

        var summary = `${data.quantity} ${data.unit}`
        if (editorData.quantitySources && editorData.quantitySources.length > 0) {
          var selectedSource = editorData.quantitySources.find(w => w.value == data.quantitySource);
          if (selectedSource) {
              summary = summary + " - " + selectedSource.label;
          }
        }

        return summary;
    }
}
