import * as qwest from 'qwest';

var Queue = function () {
  var previous = Promise.resolve();

  return function (fn) {
      return previous = previous.then(fn, fn);
  };
};

var queue = Queue();

export function asyncRequest(options: asyncRequestOptions) {

  var callback = options.callback;
  var data = options.data;

  var body = undefined;
  if (options.data) {
    body = options.data;
  }

  let requestOptions = {
    headers: {},
    withCredentials: true,
    dataType: 'post'
  }
  var headers = options.headers || {};
  if (options.addJsonHeaders !== false) {
    requestOptions.headers = {
      ...headers,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    };
    requestOptions.dataType = 'json'
  }

  // Because most server requests update the acceptance check last updated on, we have to make sure we execute the queries in turn
  // This may not always be the case, perhaps in future we will have to split out POST / PUT requests vs GET
  queue(function() {

    return qwest.map(options.method || 'GET', options.url, body, requestOptions)
      .then((xhr, json) => {
        if (xhr.status != 200) {
          return Promise.reject({
            status: 'error',
            message: xhr.status.toString()
          } as errorResponse);
        }

        if (json && json.status) {
          if (json.data && json.data.failureReason) {
            alert(json.data.failureReason);
          }
          callback(json); // Already in the applicable format.
          return;
        }

        // All of the below can be removed once the Web API endpoints have been updated to send back jsend response objects.
        if (json && json.failureReason) {
          alert(json.failureReason);
          callback({
            status: 'unauthorised',
            data: json
          } as unauthorisedResponse);
          return;
        }

        if (json.Status && json.Status == 'error') {
          callback({
            status: 'error',
            message: json.Message,
            data: json.Data
          } as errorResponse)
        }

        callback({
          status: 'success',
          data: json
        } as successResponse);
      })
      .catch((failObj: errorResponse) => {
        callback(failObj);
      });
  });
}
