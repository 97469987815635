import * as React from 'react';
const dayjs = require('dayjs');


declare namespace NewlineToBreak {
    export interface Props {
      value: string;
    }

    export interface State {
    }
}

export class NewlineToBreak extends React.Component<NewlineToBreak.Props, NewlineToBreak.State> {
  render() {

    if (!this.props.value)
      return null;

    return (
      <>
        {
          this.props.value
            .split('\n')
            .map(function(item, key) {
              return (
                <span key={key}>
                  {item}
                  <br/>
                </span>
              )
            })
        }
      </>
    );
  }
}
