let idSeed = 1;

export const newCompoundPredicateId = function() {
    return (idSeed++).toString();
}

let predicateSeeds = {} as {
    [key: string]: number
};

export const newPredicateId = function(compoundPredicateId: string) : string {
    if (!predicateSeeds[compoundPredicateId]) {
        predicateSeeds[compoundPredicateId] = 1;
    }

    var internalId = (predicateSeeds[compoundPredicateId]++);

    return `${compoundPredicateId}-${internalId}`;
}

let unitTestSeed = -1;

export const newUnitTestId = function() {
    return (unitTestSeed--);
}

let hintSeed = 1;

export const newHintId = function() {
    return (hintSeed++).toString();
}
