import * as React from 'react';
import { EditIcon, TrashIcon } from 'library/icons';

declare namespace Predicate {
    export interface Props {
        predicate: RulePredicate;
        label: string;
        editPredicate: () => void;
        deletePredicate?: () => void;
    }

    export interface State {
    }
}

export class Predicate extends React.Component<Predicate.Props, Predicate.State> {
    edit(event) {
        event.preventDefault();
    }
    render() {
        const { predicate, label, editPredicate, deletePredicate } = this.props;
        const failMessageOverride = (predicate.data as LookupPredicateData).failMessage;

        return (
            <div className="rule-condition">
                <strong>{ label }</strong>
                <div className="values">
                    {
                        predicate.summary &&
                        predicate.summary
                    }
                    {
                        !predicate.summary &&
                        "Nothing selected"
                    }
                    &nbsp;
                    <button type="button" onClick={ editPredicate } className="btn btn-link btn--marginless-icon">
                        <EditIcon />
                    </button>
                    {
                        deletePredicate &&
                        <button type="button" onClick={ deletePredicate } className="btn btn-link btn--marginless-icon">
                            <TrashIcon />
                        </button>
                    }
                </div>
                {
                    failMessageOverride && failMessageOverride.length > 0 &&
                    <>
                        <strong className="rule-condition_fail-label">Fail message</strong>
                        <div className="rule-condition_fail-message">
                            { failMessageOverride }
                        </div>
                    </>
                }
            </div>
        );
    }
}
