import * as React from 'react';
import { ListComponent, LookupComponent, ErrorMessageHelper, ErrorMessageMap } from 'library/form/components';
import { nameofFactory } from 'library/code/generics';
import { FormGroup } from 'library/form/components';

declare namespace AcFunctionEditor {
    export interface Props {
        lookupPlaceholder: string;
        predicateData: AcFunctionPredicateData;
        editorData: AcFunctionEditorMeta;
        setOnSave: EditorSetOnSave;
    }

    export interface State {
        validators: AjaxLookupResult[];
        formErrors?: ErrorMessageMap;
    }
}

export class AcFunctionEditor extends React.Component<AcFunctionEditor.Props, AcFunctionEditor.State> {

    constructor(props: AcFunctionEditor.Props, context: any) {
        super(props, context)

        const { editorData, predicateData, setOnSave } = props;

        this.onSave = this.onSave.bind(this);
        setOnSave(this.onSave);

        var selectedFunctions = props.predicateData.functions;

        if (!selectedFunctions)
        {
            selectedFunctions = [];
        }

        this.state = {
          validators: selectedFunctions,
          formErrors: {} as ErrorMessageMap
        };
    }

    render() {
        const { validators, formErrors } = this.state;
        const { editorData, predicateData } = this.props;

        const selectedValues = validators.map(m => m.value);

        var errorHelper = new ErrorMessageHelper<AcFunctionPredicateData>(formErrors);

        return (
            <>
                <FormGroup
                        label="Validation"
                        labelFor="ac-validation"
                        validationMessages={ errorHelper.errorsFor('functions') }>
                    <ListComponent listOptions={editorData.options} selectedValues={ selectedValues} onChange={ this.updateValidators } />
                </FormGroup>
            </>
        )
    }

    updateValidators = (options: AjaxLookupResult[]) => {
        this.setState({
            validators: options
        });
    }

    onSave() : EditorOnSaveData {
        const { predicateData, editorData } = this.props;
        const { validators } = this.state;

        const dataToSave = {
            ...predicateData,
            dataType: 'AcFunctionEditing',
            functions: validators
        } as AcFunctionPredicateData;

        const isValid = this.isValid(dataToSave);

        return {
            predicateData: dataToSave,
            isValid: this.isValid(dataToSave),
            summary: this.getSummary(dataToSave),
        } as EditorOnSaveData;
    }

    getSummary(data: AcFunctionPredicateData) : string {

        var summary = '';

        if (data.functions.length > 0)
            summary += data.functions.map(w => w.label).join(', ') + " "

        if (summary.length == 0)
            summary = 'Nothing selected'

        return summary;
    }

    isValid(data: AcFunctionPredicateData) : boolean {
        var errorHelper = new ErrorMessageHelper<AcFunctionPredicateData>();

        if (data.functions.length == 0) {
            errorHelper.addErrorFor('functions', 'Please select at least one function');
            return false;
        }

        this.setState({
            ...this.state,
            formErrors: errorHelper.errors
        });

        return errorHelper.isValid();
    }
}
