import * as React from 'react';
import { PredicateEditor } from './PredicateEditor';
import { TrashIcon, PlusIcon } from 'library/icons';
import { Dialog } from 'library/form/components';

declare namespace PredicateGroup {
    export interface Props {
        compoundPredicate: CompoundPredicate;
        predicateTypes: PredicateType[];
        actions?: ApplicabilityActions;
        header: string;
        showHeader?: boolean;
        renderGroups?: (editingActions: EditingActions) => React.ReactNode | React.ReactNode[];
    }

    export interface State {
        editingPredicateId: string;
        newPredicateType: string;
        addMenuDisplay: boolean;
    }

}

export interface EditingActions {
    editPredicate: (predicate: RulePredicate) => void;
    deletePredicate: (predicate: RulePredicate) => void;
}

export class PredicateGroup extends React.Component<PredicateGroup.Props, PredicateGroup.State> {

    constructor(props?: PredicateGroup.Props, context?: any) {
        super(props, context);

        this.state = {
            editingPredicateId: null,
            newPredicateType: null,
            addMenuDisplay: false
        } as PredicateGroup.State

        this.editPredicate = this.editPredicate.bind(this);
        this.deletePredicate = this.deletePredicate.bind(this);
    }

    deleteCompoundPredicate(event) {
        if (confirm('Are you sure you wish to delete this group?')) {
            this.props.actions.deleteCompoundPredicate({
                compoundPredicateId: this.props.compoundPredicate.id
            } as DeleteCompoundPredicatePayload)
        }
    }

    toggleAdd(event) {
        this.setState({...this.state,
            addMenuDisplay: this.state.addMenuDisplay ? false : true
        })
    }

    render() {
        const { header, compoundPredicate, renderGroups, showHeader } = this.props;
        const { editingPredicateId, newPredicateType } = this.state;

        const editingPredicate = compoundPredicate.predicates.find(w => w.id == editingPredicateId);

        return (
            <div className="autocheck-rule-editor_group">
                {
                    showHeader !== false &&
                    <header className="section-header btn-fade">
                        <h4 className="normal-weight">{ header }</h4>

                        <div className="right">
                            <button className="btn btn-tertiary btn-xs" onClick={ event => this.deleteCompoundPredicate(event) } type="button">
                                <TrashIcon height={10} width={10} />
                                Delete group
                            </button>
                            <button onClick={event => this.toggleAdd(event) } className="btn btn-tertiary btn-xs" type="button">
                                <PlusIcon height={10} width={10} />
                                Add condition
                            </button>
                        </div>
                    </header>
                }
                {
                    renderGroups &&
                    renderGroups({
                        deletePredicate: this.deletePredicate,
                        editPredicate: this.editPredicate
                    })
                }

                {
                    this.state.addMenuDisplay &&
                        <Dialog type='full'>
                            <header className="section-header"><h4>Add type</h4></header>
                            <ul className="ul-style-1 ">
                                {
                                    this.props.predicateTypes.map( (type) =>
                                        <li key={ type.type }>
                                            <button className="btn-link" type="button" onClick={ () => this.addPredicate(type.type) }>{type.name}</button>
                                        </li>
                                    )
                                }
                            </ul>
                            <div className={'button-wrapper'}>
                                <button onClick={event => this.toggleAdd(event) } className="btn btn-secondary btn-xs" type="button">Cancel</button>
                            </div>
                        </Dialog>
                }

                {
                    editingPredicate &&
                        <Dialog type='full'>
                            <PredicateEditor
                                isNew={false}
                                predicate={ editingPredicate }
                                predicateType={ this.findPredicateType(editingPredicate.type) }
                                onCancel={ () => this.cancelEdit() }
                                onSave={ (e) => this.updatePredicate(e) }
                            />
                        </Dialog>
                }
                {
                    newPredicateType &&
                        <Dialog type='full'>
                            <PredicateEditor
                                isNew={true}
                                predicate={ null }
                                predicateType={ this.findPredicateType(newPredicateType) }
                                onCancel={ () => this.cancelEdit() }
                                onSave={ (e) => this.addNewPredicate(e) }
                            />
                        </Dialog>
                }
            </div>
        );
    }

    findPredicateType(type: string) {
        return this.props.predicateTypes.find(f => f.type == type);
    }

    addPredicate(predicateType: string) {
        this.setState({
            editingPredicateId: null,
            newPredicateType: predicateType,
            addMenuDisplay: false
        })
    }

    editPredicate(predicate: RulePredicate) {
        this.setState({
            editingPredicateId: predicate.id,
            newPredicateType: null,
            addMenuDisplay: false
        })
    }

    deletePredicate(predicate: RulePredicate) {
        if (confirm('Are you sure you wish to remove this filter?')) {
            this.props.actions.deletePredicate({
                compoundPredicateId: this.props.compoundPredicate.id,
                predicateId: predicate.id
            } as DeletePredicatePayload);
        }
    }

    cancelEdit() {
        this.resetState();
    }

    updatePredicate(predicate: RulePredicate) {
        const { actions, compoundPredicate } = this.props;

        actions.updatePredicate({ predicate } as UpdatePredicatePayload);

        this.resetState();
    }

    addNewPredicate(predicate: RulePredicate) {
        const { actions, compoundPredicate } = this.props;

        actions.addPredicate({
                compoundPredicateId: compoundPredicate.id,
                type: this.state.newPredicateType,
                data: predicate.data,
                summary: predicate.summary
            } as CreatePredicatePayload
        );

        this.resetState();
    }

    resetState() {
        this.setState({
            editingPredicateId: null,
            newPredicateType: null
        })
    }
}
