import * as React from 'react';
import * as classNames from 'classnames';

declare namespace Dialog {
    export interface Props {
      type: 'form' | 'full' | 'alert';
      children?: React.ReactNode | React.ReactNode[];
      closeFunction?: Function;
    }

    export interface State {
    }
}

export class Dialog extends React.Component<Dialog.Props, Dialog.State> {
  dialogBackground: HTMLDivElement;

  componentDidMount() {
    document.addEventListener( "keydown", this.keyDown )
  }

  keyDown = (event) => {
    if ( event.keyCode === 27 && this.props.closeFunction !== undefined ) {
      this.props.closeFunction()
    }
  }

  backgroundClick(event) {
    if ( event.target === this.dialogBackground && this.props.closeFunction !== undefined ) {
      this.props.closeFunction()
    }
  }

  render() {
    const { type, children } = this.props;

    const dialogClasses = classNames({
      'dialog--form-align': type === 'form',
      'dialog--alert': type === 'alert',
      'dialog--full-width': type === 'full'
    }, 'dialog-background');

    return (
      <div ref={ element => this.dialogBackground = element } className={ dialogClasses } role="dialog" tabIndex={-1} onClick={e => this.backgroundClick(e)}>
        <div className="dialog-content">
          { children }
        </div>
      </div>
    );
  }
}

