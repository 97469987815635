import * as React from 'react';
import * as Autosuggest from 'react-autosuggest';
import { debounce } from 'lodash';
import { asyncRequest } from 'communication/async-request';

declare namespace Autocomplete {
    export interface Props {
      inputId?: string;
      placeholder?: any;
      apiUrl: string;
      defaultOptions?: AjaxLookupResult[];
      minSearchChars?: number;
      value?: string;
      onSuggestionSelected?: (lookup: PredicateItem) => void;
      onChange?: (value: string) => void;
    }

    export interface State {
      suggestions?: AjaxLookupResult[];
      // searchTerm is self managed if value & onChanged are not provided
      searchTerm?: string;
    }
}

// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.
const getSuggestionValue = (suggestion: PredicateItem) => suggestion.label;

// Use your imagination to render suggestions.
const renderSuggestion = (suggestion: PredicateItem) => (
  <div>
    {suggestion.label}
  </div>
);

const defaultMinSearchChars:number = 2;

function addQueryString(url: string, name: string, value: string) {
  if (!url)
    throw "URL NOT PROVIDED"

  return url.indexOf('?') > -1
    ?  `${url}&${name}=${value}`
    :  `${url}?term=${value}`
}

export class Autocomplete extends React.Component<Autocomplete.Props, Autocomplete.State> {
      constructor(props: Autocomplete.Props, context?: any) {
        super(props, context);

        // Autosuggest is a controlled component.
        // This means that you need to provide an input value
        // and an onChange handler that updates this value (see below).
        // Suggestions also need to be provided to the Autosuggest,
        // and they are initially empty because the Autosuggest is closed.
        this.state = {
          searchTerm: '',
          suggestions: []
        };

        this.suggestionSelected = this.suggestionSelected.bind(this);
        this.onSuggestionsFetchRequested = debounce(this.onSuggestionsFetchRequested.bind(this), 200);
      }

      onChange = (event, { newValue }) => {
        if (this.props.onChange) {
          this.props.onChange(newValue);
        }
        else
        {
          this.setState({
              searchTerm: newValue
          });
        }
      };

      // Autosuggest will call this function every time you need to update suggestions.
      // You already implemented this logic above, so just use it.
      onSuggestionsFetchRequested({ value }) {
        const { apiUrl, minSearchChars } = this.props;
        const actualMinSearchChars = (minSearchChars || defaultMinSearchChars);

        if (value.trim().length < actualMinSearchChars)
        {
          return;
        }

        const setState = this.setState.bind(this);

        asyncRequest({
          url: addQueryString(apiUrl, 'term', value),
          callback: function(response: jsendResponse) {
            switch (response.status) {
              case 'success':
                setState({
                  suggestions: response.data.map((m: AjaxLookupResult) => {
                    return {
                      value: m.value,
                      label: m.label
                    } as AjaxLookupResult
                  })
                });
                break;

              default:
                console.warn('There was a failure requesting the data.')
                break;
            }
          }
        });
      };

      // Autosuggest will call this function every time you need to clear suggestions.
      onSuggestionsClearRequested = () => {
        this.setState({
          suggestions: []
        });
      };

      render() {
        const { placeholder, defaultOptions, minSearchChars, inputId, value } = this.props;
        const { searchTerm, suggestions } = this.state;
        const actualMinSearchChars = (minSearchChars || defaultMinSearchChars);

        // Autosuggest will pass through all these props to the input.
        const inputProps = {
          id: inputId,
          placeholder: placeholder,
          value: value || searchTerm,
          onChange: this.onChange
        };

        let optionsToShow = suggestions;
        if (searchTerm.length < actualMinSearchChars && defaultOptions && defaultOptions.length > 0) {
          optionsToShow = defaultOptions;
        }

        // Finally, render it!
        return (
          <Autosuggest
            suggestions={optionsToShow}
            shouldRenderSuggestions={this.shouldRenderSuggestions}
            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            inputProps={inputProps}
            onSuggestionSelected={this.suggestionSelected}
          />
        );
      }

      shouldRenderSuggestions = (value) => {
        const { defaultOptions, minSearchChars } = this.props;
        const actualMinSearchChars = (minSearchChars || defaultMinSearchChars);

        if (value.trim().length < actualMinSearchChars && defaultOptions && defaultOptions.length > 0) {
          return true;
        }

        return value.trim().length >= actualMinSearchChars;
      }

      suggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
        this.props.onSuggestionSelected(suggestion);
        this.setState({
          searchTerm: ''
        });
      }
}

