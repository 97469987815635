import * as React from 'react';
import { ListComponent, LookupComponent, FormGroup, ErrorMessageHelper, ErrorMessageMap } from 'library/form/components';

declare namespace LookupEditor {
    export interface Props {
        lookupPlaceholder: string;
        predicateData: LookupPredicateData;
        editorData: LookupEditorMeta;
        setOnSave: EditorSetOnSave;
    }

    export interface State {
        listComparator: string;
        list: PredicateItem[];
        inputSource: string;
        failMessageOverride: string;
        formErrors?: ErrorMessageMap;
    }
}

export class LookupEditor extends React.Component<LookupEditor.Props, LookupEditor.State> {

    constructor(props: LookupEditor.Props, context: any) {
        super(props, context)

        const { setOnSave } = props;

        this.onSave = this.onSave.bind(this);
        setOnSave(this.onSave);

        this.state = this.getInitialState(props);
    }

    render() {
        const { listComparator, list, inputSource, failMessageOverride, formErrors } = this.state;
        const { editorData } = this.props;
        const { listComparators, inputSources } = editorData;

        var errorHelper = new ErrorMessageHelper<LookupPredicateData>(formErrors);

        return (
            <div>
                <FormGroup label="Comparison" labelFor="list-comparator" additionalWrapper="select-wrapper" validationMessages={ errorHelper.errorsFor('listComparator') }>
                    <select className="form-control" onChange={ this.listComparatorChanged } id="list-comparator" value={ listComparator || '' }>
                        <option key="default" value="">Please select an option</option>
                        {
                            listComparators.map(opt =>
                                <option value={ opt.value }
                                    key={ opt.value }>
                                    { opt.label }
                                </option>
                            )
                        }
                    </select>
                </FormGroup>
                { (inputSources && inputSources.length > 0) &&
                    <FormGroup label="Source" labelFor="input-source" additionalWrapper="select-wrapper" validationMessages={ errorHelper.errorsFor('inputSource') }>
                        <select className="form-control" onChange={ this.inputSourceChanged } id="input-source" value={ inputSource || '' }>
                            <option key="default" value="">Please select an option</option>
                            {
                                inputSources.map(opt =>
                                    <option value={ opt.value }
                                        key={ opt.value }>
                                        { opt.label }
                                    </option>
                                )
                            }
                        </select>
                    </FormGroup>
                }
                {
                    this.renderLookupComponent(list, errorHelper, "Items")
                }
                {
                    editorData.allowFailMessages &&
                    <FormGroup label="Fail message (optional)" labelFor="fail-message">
                        <textarea
                            name="fail-message"
                            id="fail-message"
                            className="form-control"
                            value={ failMessageOverride }
                            onChange={ this.failMessageOverrideChanged } />
                    </FormGroup>
                }
            </div>
        );
    }

    renderLookupComponent(lookupList: PredicateItem[], errorHelper: ErrorMessageHelper<LookupPredicateData>, additionalLabel: string)
    {
        const { lookupPlaceholder, editorData } = this.props;

        if (editorData.lookupUrl) {
            return (
                <LookupComponent
                    placeholder={ lookupPlaceholder }
                    lookupUrl={editorData.lookupUrl}
                    currentValues={ lookupList }
                    onChange={ this.updateList }
                    label={additionalLabel}
                    udgOptions={ editorData.udgOptions }
                    minSearchChars={ editorData.minSearchChars }
                    validationMessages={ errorHelper.errorsForKey('list') }
                    />
            );
        }

        const selectedValues = lookupList.map(m => m.value);
        return (
            <FormGroup label={ additionalLabel } validationMessages={ errorHelper.errorsFor('list') }>
                <ListComponent
                    listOptions={editorData.options}
                    selectedValues={ selectedValues }
                    onChange={ this.updateList } />
            </FormGroup>
        );
    }

    failMessageOverrideChanged = (e) => {
        this.setState({
            failMessageOverride: e.target.value
        });
    }

    listComparatorChanged = (e) => {
        this.setState({
            listComparator: e.target.value
        });
    }

    inputSourceChanged = (e) => {
        this.setState({
            inputSource: e.target.value
        });
    }

    updateList = (options: PredicateItem[]) => {
        this.setState({
            list: options
        } as any);
    }

    onSave() : EditorOnSaveData {
        const { predicateData } = this.props;
        const { list, listComparator, inputSource, failMessageOverride } = this.state;

        const dataToSave = {
            ...predicateData,
            dataType: 'LookupEditing',
            list: list,
            listComparator: listComparator,
            inputSource: inputSource,
            failMessage: failMessageOverride
        } as LookupPredicateData;

        return {
            predicateData: dataToSave,
            isValid: this.isValid(dataToSave),
            summary: this.getSummary(dataToSave),
        } as EditorOnSaveData;
    }

    getSummary(data: LookupPredicateData) : string {
        const { editorData } = this.props;

        if (editorData.allowNoSelections === true && data.list.length === 0) {
            return 'Any';
        }

        var summary = '';

        var comparator = editorData.listComparators
            .find(w => w.value == data.listComparator);

        if (comparator) {
            summary = comparator.label + ': '
        }

        summary += data.list.map(w => w.label).join(', ');

        if (editorData.inputSources && editorData.inputSources.length > 0) {
            var selectedSource = editorData.inputSources.find(w => w.value == data.inputSource);
            if (selectedSource) {
                summary = selectedSource.label + ", " + summary;
            }
        }

        return summary;
    }

    isValid(data: LookupPredicateData) : boolean {
        const { editorData } = this.props;

        var errorHelper = new ErrorMessageHelper<LookupPredicateData>();

        if (!data.listComparator) {
            errorHelper.addErrorFor('listComparator', 'Please select a comparison');
        }

        if (editorData.allowNoSelections !== true && data.list.length == 0) {
            // TODO: Add validation message to the screen.
            errorHelper.addErrorFor('list', 'Please select at least one option');
        }

        if (editorData.inputSources && editorData.inputSources.length > 0 && !data.inputSource) {
            errorHelper.addErrorFor('inputSource', 'Please select what values you would like to compare');
        }

        this.setState({
            ...this.state,
            formErrors: errorHelper.errors
        });

        return errorHelper.isValid();
    }

    getInitialState(props: LookupEditor.Props) : LookupEditor.State {

        const { predicateData } = props;

        let listComparator = null;
        let list = [];
        let inputSource = null;
        let failMessageOverride = '';

        if (predicateData.list) {
            list = predicateData.list;
        }

        if (predicateData.listComparator) {
            listComparator = predicateData.listComparator;
        }

        if (predicateData.inputSource) {
            inputSource = predicateData.inputSource;
        }

        if (predicateData.failMessage && predicateData.failMessage.length > 0) {
            failMessageOverride = predicateData.failMessage;
        }

        return {
            list: [...list],
            listComparator: listComparator,
            inputSource: inputSource,
            failMessageOverride: failMessageOverride,
            formErrors: {} as ErrorMessageMap
        };
    }
}
