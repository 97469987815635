import HTML5Backend from 'react-dnd-html5-backend';

export const uiSettings = {
  showBreadcrumb: true,
  dragAndDropBackend: HTML5Backend,
  dragAndDropOptions: {},
  iconSize: 15,
  report: {
    exportXsdg: true
  },
  awaitDgdContents: true
} as EnvironmentUiSettings

export const environmentType = 'website' as 'website' | 'tablet'
