import * as React from 'react';


declare namespace ErrorMessage {
    export interface Props {
      errorMessage: string;
      propertyName: string;
    }

    export interface State {
    }
}

export class ErrorMessage extends React.Component<ErrorMessage.Props, ErrorMessage.State> {
  render() {
    const { errorMessage, propertyName } = this.props

    return <span className="field-validation-error text-danger" data-valmsg-for={ propertyName } data-valmsg-replace="true">
      { errorMessage }
    </span>
  }
}
