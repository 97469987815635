import * as React from 'react';
import { Autocomplete } from './Autocomplete';
import { TrashIcon, MagicIcon, Plane } from 'library/icons';
import { FormGroup } from 'library/form/components';
import { uiSettings } from 'environment/settings';
const { iconSize } = uiSettings;

declare namespace LookupComponent {
  export interface Props {
      label?: string;
      labelFor?: string;
      placeholder: string;
      lookupUrl: string;
      udgOptions?: AjaxLookupResult[];
      validationMessages?: string[];
      minSearchChars?: number;
      readOnly?: boolean;
      highlightOperatingAirline?: boolean;

      currentValues?: PredicateItem[];
      onChange: (selectedItems: PredicateItem[]) => void;
  }

  export interface State {
  }
}

export class LookupComponent extends React.Component<LookupComponent.Props, LookupComponent.State> {

  render() {
    const { placeholder, lookupUrl, label, labelFor, udgOptions, validationMessages, minSearchChars, readOnly, highlightOperatingAirline } = this.props;

    return(
      <FormGroup
        label={ label }
        labelFor={ labelFor }
        validationMessages={ validationMessages }
        readOnly={ readOnly }>
        {
          readOnly ?
          <>
            {
              this.props.currentValues.length > 0 ?
              <ul className="form-list">
                {
                  this.props.currentValues.map((value, index) =>
                    <li key={index}>
                      {value.label}
                      { highlightOperatingAirline && index === 0 && <span className="form-list_icon" title="Operating Airline"><Plane /></span>}
                    </li>
                  )
                }
              </ul>
              :
              <>None</>
            }
          </>
          :
          <>
            <Autocomplete
              inputId={ labelFor }
              placeholder={ placeholder }
              apiUrl={ lookupUrl }
              minSearchChars={ minSearchChars }
              onSuggestionSelected={ (lookup: PredicateItem) => this.onSuggestionSelected(lookup) }
              defaultOptions={ udgOptions } />
            {
              this.props.currentValues.length > 0 &&
              <ul className="form-list">
                {
                  this.props.currentValues.map((value, index) =>
                    <li key={index}>
                      {value.label}
                      { highlightOperatingAirline && index === 0 && <span className="form-list_icon" title="Operating Airline"><Plane /></span>}
                      { (value as OperatorAjaxLookupResult).addedByPrefix &&
                        <span className="form-list_icon" title="Automatically added based on AWB"><MagicIcon></MagicIcon></span>
                      }
                      <button type="button" className="btn btn-link btn-xs btn--marginless-icon" onClick={ () => this.onDeleteItem(value) }>
                        <TrashIcon width={iconSize} height={iconSize} />
                      </button>
                    </li>
                  )
                }
              </ul>
            }
          </>
        }
      </FormGroup>
    )
  }

  onSuggestionSelected(lookup: PredicateItem) {
    const { currentValues } = this.props;

    if (currentValues.find(f => f.value == lookup.value)) {
      // Do nothing;
      return;
    }

    this.props.onChange([...currentValues, lookup]);
  }

  onDeleteItem(lookup: PredicateItem) {
    const { currentValues } = this.props;

    var newValues = currentValues.filter(w => w.value != lookup.value);

    this.props.onChange(newValues);
  }
}

