import * as React from 'react';
import { Autocomplete } from './Autocomplete';
import { TrashIcon, ExclamationMark } from 'library/icons';
import { uiSettings } from 'environment/settings';
const { iconSize } = uiSettings;

declare namespace LookupComponentSingle {
  export interface Props {
      inputId?: string;
      placeholder: string;
      lookupUrl: string;
      minSearchChars?: number;
      warningMessage?: string;

      value?: PredicateItem;
      onChange: (e: {target: {value: PredicateItem}}) => void;
  }

  export interface State {
    searchTerm: string;
  }
}

export class LookupComponentSingle extends React.Component<LookupComponentSingle.Props, LookupComponentSingle.State> {

  render() {
    const { inputId, placeholder, lookupUrl, minSearchChars, warningMessage, value } = this.props;

    return (
      <>
        {
          value ?
          <ul className="form-list">
            <li className="form-list_item--single">  
              {value.label}
              { warningMessage &&     
                <span className="form-list_icon" title={warningMessage}>
                  <ExclamationMark fill='#ffbf00' width={iconSize} height={iconSize} />
                </span>
              }
              <button type="button" className="btn btn-link btn-xs btn--marginless-icon" onClick={ () => this.onDeleteValue() }>
                <TrashIcon width={iconSize} height={iconSize} />
              </button>
            </li>
          </ul>
          :
          <Autocomplete
            inputId={ inputId }
            placeholder={ placeholder }
            apiUrl={ lookupUrl }
            minSearchChars={ minSearchChars }
            value={ this.state && this.state.searchTerm || '' }
            onChange={ this.onSuggestionTermChanged }
            onSuggestionSelected={ (lookup: PredicateItem) => this.onSuggestionSelected(lookup) } />
        }
      </>
    )
  }

  onSuggestionTermChanged = (searchTerm: string) => {
    this.setState({
      searchTerm: searchTerm
    });
  }

  onSuggestionSelected(lookup: PredicateItem) {
    this.props.onChange({
      target: {
        value: lookup
      },
    });
    this.setState({
      searchTerm: ''
    })
  }

  onDeleteValue() {
    this.props.onChange({
      target: {
        value: null
      },
    });
  }
}

