

export function useDefaultSvgDims(props: React.SVGProps<SVGSVGElement> & SvgTitleProps) : SvgTitleProps & { svgProps: React.SVGProps<SVGSVGElement> } {
  const { title, ...optionalSvg} = props;
  let svgProps = {
    ...optionalSvg,
    height: props.height ? props.height : 15,
    width: props.width ? props.width : 15
  }

  return { title, svgProps };
}
