import { RootState } from "checkspa/reducers";
import * as classNames from "classnames";
import * as React from "react";
import { useSelector } from "react-redux";
const dayjs = require("dayjs");

interface DateUiProps {
  date?: Date;
  showTime?: boolean;
  emptyText?: string;
  timezoneType?: TimezoneType;
  showOffset?: boolean;
}

function selectTimezoneSettings(
  state: RootState
): Timezones | null | undefined {
  return state.userSettingData ? state.userSettingData.timezones : null;
}

function zeroPad(value: number) : string {
  // pad the number to 2 digits without using padStart
  return value < 10 ? `0${value}` : value.toString();
}

// Describe the offset of the timezone
function getTimezoneDescription(offset: TimezoneOffset) {
  if (offset.hours === 0 && offset.minutes === 0) {
    return "UTC";
  }

  const sign = offset.hours < 0 ? "-" : "+";
  const hour = zeroPad(Math.abs(offset.hours));
  const minute = zeroPad(offset.minutes);

  return `UTC${sign}${hour}:${minute}`;
}

function timezoneSettingFor(
  timezoneType: TimezoneType,
  timezoneSettings: Timezones
) {
  return timezoneSettings[timezoneType];
}

export function DateUi({
  date,
  showTime,
  showOffset,
  timezoneType,
  emptyText = "",
}: DateUiProps) {
  const timezones = useSelector(selectTimezoneSettings);
  const dateFormat = "DD MMM YYYY";
  const dateTimeFormat = `${dateFormat} HH:mm`;
  const relevantFormat = showTime ? dateTimeFormat : dateFormat;
  const datejs = dayjs(date);

  function renderDateTimezone(
    settings: TimezoneSettings,
    hideWhenNotSelected: boolean
  ) {
    const adjustedDate = datejs
      .add(settings.offset.hours, "hour")
      .add(settings.offset.minutes, "minutes");

    return (
      <span
        className={classNames({ [settings.className]: hideWhenNotSelected })}
        title={settings.title}
      >
        {adjustedDate.format(relevantFormat)}{" "}
        {showOffset ? getTimezoneDescription(settings.offset) : ""}
      </span>
    );
  }

  if (!date) {
    return <>{emptyText}</>;
  }

  // When there are no timezone settings, always render UTC (e.g. Tablet / Admin)
  if (!timezones) {
    return <>{datejs.format(relevantFormat)}</>;
  }

  // When a specific timezone type is required then show that without the class that's used for hiding variations
  if (timezoneType) {
    const timezoneSettings = timezoneSettingFor(timezoneType, timezones);
    return renderDateTimezone(timezoneSettings, false);
  }

  return (
    <>
      {renderDateTimezone(timezones.system, true)}
      {renderDateTimezone(timezones.office, true)}
      {renderDateTimezone(timezones.user, true)}
    </>
  );
}
